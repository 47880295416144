import Route from "@/router/Route";

const Projects = () => {
  return import(
    /* webpackChunkName: "projects" */ "@/components/Pages/Projects/Projects.vue"
  );
};

export default new Route({
  path: "/projects",
  name: "Projects",
  showInMenu: true,
  icon: "mdi-firework",
  component: Projects,
  meta: {
    title: "Projects",
    description: "Nikola Nikushev",
    tags: []
  }
});
