import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";

export const parse = (params: Dictionary<string>, t: string) => {
  Object.keys(params).forEach(param => {
    const regex = new RegExp(`:${param}`, "g");

    t = t.replace(regex, params[param]);
  });
  return t;
};

export const parseTitle = (to: Route) => {
  let t = to.meta?.title;
  if (!t) {
    return "";
  }
  t = parse(to.params, t);
  if (!t) {
    t = "";
  } else {
    t = " - " + t;
  }
  return t;
};

export const applyMetaToRoute = (to: Route) => {
  document.title = `nikolanikushev${parseTitle(to)}`;
  const SEOElements = document.querySelectorAll('[data-id^="SEO"]');
  SEOElements.forEach(htmlElements => {
    const elementId = htmlElements.getAttribute("data-id");
    if (!elementId) {
      return;
    }
    const metaId = elementId.replace("SEO.", "");
    const metaValue = to.meta ? to.meta[metaId] : undefined;
    if (!metaValue) {
      return;
    }
    const content = parse(to.params, metaValue);
    if (!content) {
      return;
    }
    htmlElements.setAttribute("content", content);
  });
};
