



















































import Vue from "vue";
import routes from "@/router/routes";
import { RouteInterface } from "@/router/RouteInterface";
import { Route } from "vue-router";
import SocialMedia from "@/components/SocialMedia.vue";

export default Vue.extend({
  name: "Menu",
  components: { SocialMedia },
  created() {
    this.routeName = this.$route.path;
  },
  watch: {
    $route(to: Route) {
      this.routeName = to.path;
      this.drawer = false;
    }
  },
  data() {
    return {
      drawer: false,
      routes: routes.filter(el => (el as RouteInterface).showInMenu),
      routeName: "",
      isScrolling: false
    };
  },
  methods: {
    onScroll() {
      const offset = window.pageYOffset;
      this.isScrolling = offset > 30;
    }
  }
});
