






export default {
  name: "BaseBtn"
};
