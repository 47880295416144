import Route from "@/router/Route";

const Timeline = async () => {
  return import(
    /* webpackChunkName: "timeline" */ "@/components/Pages/Timeline/Timeline.vue"
  );
};

export default new Route({
  path: "/timeline",
  name: "Timeline",
  showInMenu: true,
  icon: "mdi-chart-timeline-variant",
  component: Timeline,
  meta: {
    title: "Timeline",
    description: "Nikola Nikushev",
    tags: []
  }
});
