


























import Vue from "vue";

export default Vue.extend({
  name: "HeaderImage",
  props: {
    topText: { type: String },
    imageUrl: { type: String },
    topSubText: { type: String }
  }
});
