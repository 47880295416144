






































import Vue from "vue";

export default Vue.extend({
  name: "PostList",
  props: {
    posts: { type: Array },
    typeOfPost: {
      type: String,
      default() {
        return "projects";
      }
    }
  }
});
