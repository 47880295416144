import { Route } from "vue-router";
import router from "@/router";

const validateTokenOrRefresh = async (next: Function, redirect: string) => {
  if (redirect) {
    await router.push(redirect);
  }
  return next();
};

export default async (to: Route, from: Route, next: Function) => {
  const redirect: string = from
    ? from.query
      ? (from.query.redirect as string) || ""
      : ""
    : "";
  if (from && from.query) {
    from.query.redirect = "";
  }
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (!requiresAuth) {
    return next();
  }
  return await validateTokenOrRefresh(next, redirect);
};
