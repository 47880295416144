import { RouteInterface } from "./RouteInterface";
import HomeRoute from "@/router/routes/HomeRoute";
import ContactRoute from "@/router/routes/ContactRoute";
import NewsRoute from "@/router/routes/NewsRoute";
import ProjectsRoute from "@/router/routes/ProjectsRoute";
import TimelineRoute from "@/router/routes/TimelineRoute";
import PageNotFoundRoute from "@/router/routes/PageNotFoundRoute";
import AboutRoute from "@/router/routes/AboutRoute";
import ProjectRoute from "@/router/routes/ProjectRoute";
import NewsPostRoute from "@/router/routes/NewsPostRoute";

export type NonAuthRoutesType = {
  home: RouteInterface;
  about: RouteInterface;
  contact: RouteInterface;
  news: RouteInterface;
  newsPost: RouteInterface;
  project: RouteInterface;
  projects: RouteInterface;
  timeline: RouteInterface;
  everythingElse: RouteInterface;
};

export const notAuthRoutes: NonAuthRoutesType = {
  home: HomeRoute,
  contact: ContactRoute,
  news: NewsRoute,
  newsPost: NewsPostRoute,
  project: ProjectRoute,
  projects: ProjectsRoute,
  timeline: TimelineRoute,
  about: AboutRoute,
  everythingElse: PageNotFoundRoute
};
