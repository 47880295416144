import Route from "@/router/Route";

const PageNotFound = () => {
  return import(
    /* webpackChunkName: "page404" */ "@/components/Pages/Errors/PageNotFound.vue"
  );
};

export default new Route({
  path: "*",
  name: "PageNotFound",
  showInMenu: false,
  component: PageNotFound,
  meta: {
    title: "Page not Found",
    description: "The page your have requested is not found",
    tags: []
  }
});
