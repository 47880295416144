import Route from "@/router/Route";

const Home = () => {
  return import(
    /* webpackChunkName: "home" */ "@/components/Pages/Home/Home.vue"
  );
};

export default new Route({
  path: "/",
  name: "Home",
  icon: "mdi-home",
  showInMenu: true,
  component: Home,
  meta: {
    title: "Home",
    description: "Nikola Nikushev",
    tags: []
  }
});
