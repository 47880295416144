import Route from "@/router/Route";

const Post = () => {
  return import(
    /* webpackChunkName: "news" */ "@/components/Pages/Post/Post.vue"
  );
};

export default new Route({
  path: "/projects/:id/:title",
  name: "Project",
  showInMenu: false,
  icon: "mdi-newspaper-variant",
  component: Post,
  meta: {
    title: "Project",
    description: "Nikola Nikushev",
    tags: []
  }
});
