import Vue from "vue";
import BaseBtn from "@/components/base/Btn.vue";
import BaseHeading from "@/components/base/Heading.vue";
import BaseSubheading from "@/components/base/Subheading.vue";
import BaseText from "@/components/base/Text.vue";
import BaseBubble1 from "@/components/base/Bubble1.vue";
import BaseBubble2 from "@/components/base/Bubble2.vue";
import HeaderImage from "@/components/HeaderImage/HeaderImage.vue";
import ImageContainer from "@/components/ImageContainer/ImageContainer.vue";
import PostDateFormat from "@/components/PostDateFormat.vue";
import PostList from "@/components/PostList.vue";

Vue.component("BaseBtn", BaseBtn);
Vue.component("BaseHeading", BaseHeading);
Vue.component("ImageContainer", ImageContainer);
Vue.component("PostList", PostList);
Vue.component("PostDateFormat", PostDateFormat);
Vue.component("HeaderImage", HeaderImage);
Vue.component("BaseSubheading", BaseSubheading);
Vue.component("BaseText", BaseText);
Vue.component("BaseBubble1", BaseBubble1);
Vue.component("BaseBubble2", BaseBubble2);
