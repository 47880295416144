import Route from "@/router/Route";

const News = () => {
  return import(
    /* webpackChunkName: "news" */ "@/components/Pages/News/News.vue"
  );
};

export default new Route({
  path: "/news",
  name: "News",
  showInMenu: true,
  icon: "mdi-newspaper-variant",
  component: News,
  meta: {
    title: "News",
    description: "Nikola Nikushev",
    tags: []
  }
});
