import { RouteConfig } from "vue-router";
import { RouteInput, RouteInterface } from "@/router/RouteInterface";

export interface RouteMeta {
  title: string;
  description: string;
  tags: string;
  requiresAuth?: boolean;
}

export default class Route implements RouteInterface {
  tags: string[] = ["friends", "interests", "social", "platform"];
  children?: RouteConfig[];
  icon?: string;
  showInMenu = true;
  // @ts-ignore eslint-disable-next-line
  component: any;
  meta: RouteMeta;
  name: string;
  path: string;

  constructor(input: RouteInput) {
    this.children = input.children;
    this.component = input.component;
    this.meta = {
      ...input.meta,
      tags: input.meta.tags.concat(this.tags).join(",")
    };
    this.name = input.name;
    this.path = input.path;
    this.icon = input.icon;
    this.showInMenu = input.showInMenu;
  }

  generate(id?: string, name?: string): string {
    if (!id) {
      return this.path;
    }
    if (!name) {
      return `${this.path}/${id}`;
    }
    return `${this.path}/${id}/${name}`;
  }
}
