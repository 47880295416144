import Vue from "vue";
import Router, { Route } from "vue-router";
import routes from "@/router/routes";
import { applyMetaToRoute } from "@/router/tools";
import validateIfUserNeedsAuthentication from "@/router/tools/validateIfUserNeedsAuthentication";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
router.beforeEach((to: Route, from: Route, next: Function) => {
  applyMetaToRoute(to);
  next();
});
router.beforeEach(async (to: Route, from: Route, next: Function) => {
  if (from.path) {
    localStorage.setItem("previousPath", from.path);
  }

  if (to.path === "/configure") {
    return next();
  }

  return await validateIfUserNeedsAuthentication(to, from, next);
});
export default router;
