import Route from "@/router/Route";

const Contact = () => {
  return import(
    /* webpackChunkName: "contact" */ "@/components/Pages/Contact/Contact.vue"
  );
};

export default new Route({
  path: "/contact",
  name: "Contact",
  icon: "mdi-contacts",
  showInMenu: true,
  component: Contact,
  meta: {
    title: "Contact",
    description: "Nikola Nikushev",
    tags: []
  }
});
