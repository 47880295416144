
















import Vue from "vue";

export default Vue.extend({
  name: "WebFooter",
  data() {
    const releaseYear = 2017;
    const today = new Date();
    const thisYear = today.getUTCFullYear();
    let yearText = `${releaseYear}`;
    if (thisYear > releaseYear) {
      yearText = `${releaseYear} - ${thisYear}`;
    }
    return {
      yearText,
      links: [
        this.$routes.home,
        this.$routes.contact,
        this.$routes.about,
        this.$routes.projects,
        this.$routes.news
      ]
    };
  }
});
