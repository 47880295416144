





















export default {
  name: "PostDateFormat",
  props: {
    date: {}
  }
};
