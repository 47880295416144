import Route from "@/router/Route";

const About = () => {
  return import(
    /* webpackChunkName: "about" */ "@/components/Pages/About/About.vue"
  );
};

export default new Route({
  path: "/about",
  name: "About",
  showInMenu: true,
  icon: "mdi-help-box",
  component: About,
  meta: {
    title: "About",
    description: "Nikola Nikushev",
    tags: []
  }
});
