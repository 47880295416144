

















export default {
  name: "SocialMedia",
  props: {
    large: {
      type: Boolean,
      default: false
    }
  },

  data: () => {
    return {
      items: [
        {
          name: "Instagram",
          icon: "mdi-instagram",
          link: "https://www.instagram.com/nikola_nikushev/"
        },
        {
          name: "Linkedin",
          icon: "mdi-linkedin",
          link: "https://www.linkedin.com/in/nikolanikushev"
        },
        {
          name: "Facebook",
          icon: "mdi-facebook",
          link: "https://www.facebook.com/nikola.nikushev/"
        },
        {
          name: "Email",
          icon: "mdi-email",
          link: "mailto: nikola.nikushev@hotmail.com"
        }
      ]
    };
  }
};
