import { notAuthRoutes, NonAuthRoutesType } from "@/router/nonAuthRoutes";

import Vue from "vue";
import { RouteInterface } from "./RouteInterface";
import { RouteConfig } from "vue-router";

type AuthRouterType = RouteInterface & {
  children: RouteConfig[];
};

const routes = {
  ...notAuthRoutes
};

declare module "vue/types/vue" {
  interface Vue {
    $routes: NonAuthRoutesType & AuthRouterType;
  }
}

Vue.prototype.$routes = routes;

export default Object.values(routes) as RouteConfig[];
