import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);
Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#D3C3BC",
        action: "#1976d2",
        secondary: "#BFADA5",
        accent: "#D8EBF1",
        info: "#5B5C79"
      }
    }
  }
});
