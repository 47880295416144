










import Vue from "vue";
import Menu from "@/components/Menu/Menu.vue";
import WebFooter from "@/components/WebFooter/WebFooter.vue";

export default Vue.extend({
  name: "App",

  components: {
    Menu,
    WebFooter
  }
});
