import Route from "@/router/Route";

const NewsPostRoute = () => {
  return import(
    /* webpackChunkName: "news" */ "@/components/Pages/Post/Post.vue"
  );
};

export default new Route({
  path: "/news/:id/:title",
  name: "News",
  showInMenu: false,
  component: NewsPostRoute,
  meta: {
    title: ":title",
    description: "Nikola Nikushev",
    tags: []
  }
});
