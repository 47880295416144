



























































import Vue from "vue";
import VueGallery from "vue-gallery";

export default Vue.extend({
  name: "ImageContainer",
  components: {
    VueGallery
  },
  data() {
    return { index: null };
  },
  props: {
    topText: { type: String },
    id: { type: String },
    imageUrl: { type: String },
    topSubText: { type: String },
    hideRightSection: {
      type: Boolean,
      default() {
        return false;
      }
    },
    images: {
      type: Array,
      default() {
        return [];
      }
    },
    classType: {
      type: String,
      default() {
        return "primary";
      }
    }
  }
});
