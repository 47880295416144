<template>
  <v-img
    class="grow hidden-sm-and-down"
    contain
    :max-height="maxHeight || 200"
    width="100%"
    position="top right"
    :src="require('@/assets/bubbles1.png')"
  />
</template>

<script>
export default {
  name: "BaseBubble1",
  props: {
    maxHeight: Number
  }
};
</script>
